import React from "react";
import {
  Container,
  Box,
  Title,
  Text,
  List,
  Divider,
  Anchor,
} from "@mantine/core";
import DefaultFooter from "../../components/Footers/DefaultFooter";

const TermsOfUse = () => {
  return (
    <>
        <Container size="sm" my="xl">
        <Title order={1} align="center" mb="xl">
            Terms of Use
        </Title>
        <Text align="center" color="dimmed" size="sm" mb="xl">
            Effective Date: Jan. 1, 2025
        </Text>
        <Divider my="lg" />

        <Box mb="lg">
            <Text>
            Welcome to The Origami Company. Please read on to learn the rules and
            restrictions that govern your use of our website(s), products,
            services, applications, and/or other online or offline communications
            (the “Services”). If you have any questions, comments, or concerns
            regarding these terms or the Services, please contact us at:
            </Text>
            <List mt="sm" spacing="xs">
            <List.Item>
                <Text>
                Email:{" "}
                <Anchor href="mailto:admin@theorigamicompany.com">
                    admin@theorigamicompany.com
                </Anchor>
                </Text>
            </List.Item>
            <List.Item>
                <Text>Phone: +1 (832) 818-2062</Text>
            </List.Item>
            <List.Item>
                <Text>Address: 12670 Jebbia Ln, Stafford, TX 77477</Text>
            </List.Item>
            </List>
        </Box>
        <Divider my="lg" />

        <Box mb="lg">
            <Text weight={700} color="red" mb="sm">
            PLEASE NOTE: THESE TERMS OF USE CONTAIN AN ARBITRATION CLAUSE AND
            CLASS ACTION WAIVER.
            </Text>
            <Text>
            ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES
            OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU
            AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
            INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
            CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. BY ACCEPTING THESE
            TERMS OF USE, YOU AGREE TO BE BOUND BY THE ARBITRATION SECTION BELOW.
            PLEASE READ IT CAREFULLY.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mb="lg">
            <Title order={2} mt="lg">
            1. Will these Terms ever change?
            </Title>
            <Text mt="sm">
            We are constantly trying to improve our Services, so these Terms may
            need to change along with our Services. We reserve the right to
            change the Terms at any time, but if we do, we will place a notice on
            our site located at{" "}
            <Anchor href="https://www.theorigamicompany.com/" target="_blank">
                https://www.theorigamicompany.com/
            </Anchor>
            , send you an email, and/or notify you by some other means.
            </Text>
            <Text mt="sm">
            If you don’t agree with the new Terms, you are free to reject them;
            unfortunately, that means you will no longer be able to use the
            Services. If you use the Services in any way after a change to the
            Terms is effective, that means you agree to all of the changes.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mb="lg">
            <Title order={2}>2. What about my privacy?</Title>
            <Text mt="sm">
            The Origami Company takes the privacy of its users very seriously.
            For the current The Origami Company Privacy Policy, please{" "}
            <Anchor href="https://www.theorigamicompany.com/privacy" target="_blank">
                click here
            </Anchor>
            .
            </Text>
            <Text mt="sm" weight={700}>
            Children’s Online Privacy Protection Act:
            </Text>
            <Text mt="sm">
            The Children’s Online Privacy Protection Act (“COPPA”) requires that
            online service providers obtain parental consent before they knowingly
            collect personally identifiable information online from children who
            are under 16 years of age. We do not knowingly collect or solicit
            personally identifiable information from children under 16 years of
            age; if you are a child under 16 years of age, please do not attempt
            to register for or otherwise use the Services or send us any personal
            information.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mb="lg">
            <Title order={2}>3. What are the basics of using The Origami Company?</Title>
            <Text mt="sm">
            You may be required to sign up for an account, select a password and
            user name (“The Origami Company User ID”), and provide us with certain
            information or data, such as your contact information. You promise to
            provide us with accurate, complete, and updated registration
            information about yourself.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mb="lg">
            <Title order={2}>4. What about messaging?</Title>
            <Text mt="sm">
            As part of the Services, you may receive communications through the
            Services, including messages that The Origami Company sends you (for
            example, via email or SMS). You may opt in to receive The Origami
            Company’s text messages (using autodialer technology) at the mobile
            number you provide to us.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mb="lg">
            <Title order={2}>
            5. Are there restrictions in how I can use the Services?
            </Title>
            <Text mt="sm">
            You represent, warrant, and agree that you will not contribute any
            Content or User Submission or otherwise use the Services or interact
            with the Services in a manner that violates these Terms.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box>
            <Title order={2}>6. What are my rights in the Services?</Title>
            <Text mt="sm">
                The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions (as defined below) and so forth (all of the foregoing, the “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including The Origami Company's) rights.
            </Text>
            <br/>
            <Text>
            Subject to these Terms, we grant each user of the Services a limited, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services in the U.S.. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Services is expressly prohibited without prior written permission from us. You understand that The Origami Company owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section still apply.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box>
            <Title order={2}>7. What about anything I contribute to the Services – do I have to grant any licenses to The Origami Company or to other users?</Title>
            <Text mt="sm" fw={500}>User Submissions</Text>
            <Text mt="sm">
                We may, in our sole discretion, permit you to post, upload, input, publish, submit or transmit information or material through the Service. Anything you post, upload, share, store, or otherwise provide through the Services is your “User Submission”. You are solely responsible for all User Submissions you contribute to the Services. You represent that all User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.
            </Text>
            <Text mt="sm">
                You agree that you will not post, upload, share, store, or otherwise provide through the Services any User Submissions that: (i) infringe any third party's copyrights or other rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; or (viii) that violate any law.
            </Text>

            <Text mt="md" fw={500}>Licenses</Text>
            <Text mt="sm">
                You grant us certain rights in those User Submissions (see below for more information). Please note that all of the following licenses are subject to our Privacy Policy to the extent they relate to User Submissions that are also your personally-identifiable information.
            </Text>
            <Text mt="sm">
                By submitting User Submissions through the Services, you hereby do and shall grant The Origami Company a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with this site, the Services and our (and our successors’ and assigns’) businesses, including without limitation for promoting and redistributing part or all of this site or the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds), and including after your termination of your account or the Services. For clarity, the foregoing license grants to us does not affect your other ownership or license rights in your User Submissions, including the right to grant additional licenses to your User Submissions, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.
            </Text>
            <Text mt="sm">
                Certain features of the Services allow you to share information with others, including through your social networks or other Third Party Accounts. When Content is authorized for sharing, we will clearly identify the Content you are authorized to redistribute and the ways you may redistribute it, usually by providing a “share” button on or near the Content. If you share information from the Services with others through your Third Party Accounts, such as your social networks, you authorize The Origami Company to share that information with the applicable Third Party Account provider. Please review the policies of any Third Party Account providers you share information with or through for additional information about how they may use your information. If you redistribute Content, you must be able to edit or delete any Content you redistribute, and you must edit or delete it promptly upon our request.
            </Text>
            <Text mt="sm">
                Finally, you understand and agree that The Origami Company, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mt="lg">
            <Title order={2}>8. What if I see something on the Services that infringes my copyright?</Title>
            <Text mt="sm">
                We respect others’ intellectual property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers.
            </Text>
            <Text mt="sm">
                If you believe any User Submission accessible on or from the Services infringes your copyright, you may request removal of those materials (or access thereto) from the Services by contacting us (address identified above) and providing the following information: (A) identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (e.g., URL) of an authorized version of the work; (B) identification of the User Submission that you believe to be infringing and its location; (C) your name, address, telephone number, and email address; (D) a statement that you have a good faith belief that the complained of use of the work is not authorized by the copyright owner, its agent, or the law; (E) a statement that the information you have supplied is accurate, and indicating that “under penalty of perjury,” you are the copyright owner or authorized representative; and (F) signature or the electronic equivalent from the copyright holder or authorized representative.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mt="lg">
            <Title order={2}>9. Who is responsible for what I see and do on the Services?</Title>
            <Text mt="sm">
                Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such Content originated, and you access all such information and Content at your own risk, and we aren’t liable for any errors or omissions in that information or Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.
            </Text>
            <Text mt="sm">
                You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.
            </Text>
            <Text mt="sm">
                The Services may contain links or connections to third-party websites or services that are not owned or controlled by The Origami Company. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and that The Origami Company is not responsible for such risks.
            </Text>
            <Text mt="sm">
                The Origami Company has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with through the Services. In addition, The Origami Company will not and cannot monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.
            </Text>
            <Text mt="sm">
                Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that The Origami Company shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.
            </Text>
            <Text mt="sm">
                If there is a dispute between participants on this site or Services, or between users and any third party, you agree that The Origami Company is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release The Origami Company, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mt="lg">
            <Title order={2}>10. Will The Origami Company ever change the Services?</Title>
            <Text mt="sm">
                We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We’ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn’t always practical. We reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mt="lg">
            <Title order={2}>11. Do the Services cost anything?</Title>
            <Text mt="sm">
                Our website(s) and app(s) are currently offered free of charge. We may charge a fee for using the Services. If you are using a free version of the Services, we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services. Note that if you elect to receive text messages through the Services, data and message rates may apply. Any and all such charges, fees or costs are your sole responsibility. You should consult with your wireless carrier to determine what rates, charges, fees or costs may apply to your use of the Services.
            </Text>
            <Text mt="sm">
                Paid Services. Certain of our Services may be subject to payments now or in the future, such as wash & fold, dry, or related services (the “Cleaning Services”, and collectively the “Paid Services”). Please see our Pricing page for a description of the current Paid Services. Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of these Terms.
            </Text>
            <Text mt="sm">
                Billing. We use a third-party payment processor (the “Payment Processor”) to bill you through a payment account linked to your account on the Services (your “Billing Account”) for use of the Paid Services. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these Terms. Currently, we use Stripe, Inc. as our Payment Processor. You can access Stripe's Services Agreement and Privacy Policy at https://stripe.com/privacy. We are not responsible for any error by, or other acts or omissions of, the Payment Processor. By choosing to use Paid Services, you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms, and you authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that the Payment Processor makes even if it has already requested or received payment.
            </Text>
            <Text mt="sm">
                Payment Method. The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.
            </Text>
            <Text mt="sm">
                Preauthorized Amount. Once you place an order, you agree to pay a preauthorized amount equal to your estimated order total, which we will calculate in advance of your order being delivered. The preauthorized amount will vary but is typically calculated to be the total estimated cost of your order based upon work items or weight of the order received. A small difference between your preauthorized amount and the final charge may exist due to potential Services replaced at a different price, and the actual weight of clothes. We will release the difference after your order is delivered. You may be charged multiple preauthorized amounts on an order if adjustments made after the initial preauthorization exceed the original estimated total amount. You may also have additional charges on your order if not all work items are ready at the time of the delivery of the preauthorized order and we need to charge or adjust for extra work items. You agree that we may accumulate preauthorized charges incurred and submit them as one or more aggregate charges. Please note that it can then take several days for the difference to be processed and issued to you, depending on your payment provider’s processes. If you have more questions, please contact your payment provider. You agree that an unsuccessful preauthorization will result in a temporary hold of your items. If the failed preauthorization is not cleared within 90 days after notification by The Origami Company, The Origami Company has the right to donate or dispose of such items as we see fit.
            </Text>
            <Text mt="sm">
                Recurring Billing. Some of the Paid Services may consist of an initial period, for which there is a one-time charge, followed by recurring period charges as agreed to by you. By choosing a recurring payment plan, you acknowledge that such Services have an initial and recurring payment feature and you accept responsibility for all recurring charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO ACCOUNT SETTINGS.
            </Text>
            <Text mt="sm">
                Current Information Required. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT SETTINGS. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.
            </Text>
            <Text mt="sm">
                Auto-Renewal for Paid Services. Unless you opt out of auto-renewal, which can be done through your account settings, any Paid Services you have signed up for will be automatically extended for successive renewal periods of the same duration as the subscription term originally selected, at the then-current non-promotional rate. To change or resign your Paid Services at any time, go to account settings. If you terminate a Paid Service, you may use your subscription until the end of your then-current term, and your subscription will not be renewed after your then-current term expires. However, you will not be eligible for a prorated refund of any portion of the subscription fee paid for the then-current subscription period. If you do not want to continue to be charged on a recurring monthly basis, you must cancel the applicable Paid Service through your account settings or terminate your THE ORIGAMI COMPANY account before the end of the recurring TERM. Paid Services cannot be terminated before the end of the period for which you have already paid, and except as expressly provided in these terms, THE ORIGAMI COMPANY will not refund any fees that you have already paid.
            </Text>
            <Text mt="sm">
                Reaffirmation of Authorization. Your non-termination or continued use of a Paid Service reaffirms that we are authorized to charge your Payment Method for that Paid Service. We may submit those charges for payment and you will be responsible for such charges. This does not waive our right to seek payment directly from you. Your charges may be payable in advance, in arrears, per usage, or as otherwise described when you initially selected to use the Paid Service.
            </Text>
            <Text mt="sm">
                Free Trials and Other Promotions. Any free trial or other promotion that provides access to a Paid Service must be used within the specified time of the trial. You must stop using a Paid Service before the end of the trial period in order to avoid being charged for that Paid Service. If you cancel prior to the end of the trial period and are inadvertently charged for a Paid Service, please contact us at admin@theorigamicompany.com. Any and all offers or promotions advertised on the Services are void where prohibited, and are subject to the posting of any official rules to such offers or promotions.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box>
            <Title order={2}>12. What if I want to stop using the Services?</Title>
            <Text mt="sm">
                You’re free to do that at any time through your account settings or by contacting us at 
                <Text component="a" href="mailto:admin@theorigamicompany.com" color="blue">admin@theorigamicompany.com</Text>; 
                please refer to our Privacy Policy, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.
            </Text>
            <Text mt="sm">
                The Origami Company is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, including your breach of these Terms. 
                The Origami Company has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.
            </Text>
            <Text mt="sm">
                Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.
            </Text>
            <Text mt="sm">
                If you have deleted your account by mistake, contact us immediately at 
                <Text component="a" href="mailto:admin@theorigamicompany.com" color="blue">admin@theorigamicompany.com</Text> – 
                we will try to help, but unfortunately, we can’t promise that we can recover or restore anything.
            </Text>
            <Text mt="sm">
                Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: 
                any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes 
                between us, including without limitation the arbitration agreement.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box mt="lg">
            <Title order={2}>13. What else do I need to know?</Title>
            <Text mt="sm" fw={500}>1) Your obligations</Text>
            <Text mt="sm">
                You know your clothes and your needs best. Before sending your clothes to The Origami Company, you will:
            </Text>
            <List mt="sm" spacing="xs">
                <List.Item>
                    <Text mt="sm">
                        Make the cleaning requests that adhere to the general laundry instructions as specified on the label of the clothing items (for example, don’t put items that are “dry clean only” in the Wash & Fold bag).
                    </Text>
                </List.Item>
                <List.Item>
                    <Text mt="sm">
                        Check your clothes and make sure no non-washable items are included in an order, and all orders include the accurate number of items.
                    </Text>
                </List.Item>
                <List.Item>
                    <Text mt="sm">
                        Notify The Origami Company of any clothing defects that you are aware of, as well as any special care requirements.
                    </Text>
                </List.Item>
            </List>
            
            <Text mt="sm">
                You further agree to allow for prompt delivery of your cleaned clothes, or to promptly pick up your cleaned clothes if you choose to do so yourself. If you fail to successfully receive 
                a delivery or pick up your clothes within 90 days of being notified by The Origami Company of their readiness for delivery, The Origami Company has the right to donate or dispose of them 
                as we see fit. In order to enable a successful delivery or pick up of a completed order, you must have an acceptable payment on file with The Origami Company so that we can charge you for 
                the services provided upon delivery.
            </Text>

            <Text mt="sm" fw={500}>2) Warranty Disclaimer</Text>
            <Text mt="sm">
                The Origami Company and its licensors, suppliers, partners, parent, subsidiaries, or affiliated entities, and each of their respective officers, directors, members, employees, consultants, 
                contract employees, representatives and agents, and each of their respective successors and assigns (The Origami Company and all such parties together, the “The Origami Company Parties”) 
                make no representations or warranties concerning the Services, including without limitation regarding any Content contained in or accessed through the Services, and the The Origami Company 
                Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any claims, actions, suits 
                procedures, costs, expenses, damages, or liabilities arising out of use of, or in any way related to your participation in, the Services.
            </Text>
            <Text mt="sm">
                THE SERVICES AND CONTENT ARE PROVIDED BY THE ORIGAMI COMPANY (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
            </Text>
            <Text mt="sm">
                SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </Text>

            <Text mt="sm" fw={500}>3) Limitation of Liability</Text>
            <Text mt="sm" fw={500} underline>
                (a) Damaged or lost Laundry
            </Text>
            <Text mt="sm">
                The Origami Company has implemented measures designed to prevent the situations of lost or damaged items from happening, but cannot guarantee that such situations will never happen. 
                As such, you acknowledge and agree that:
            </Text>
            <List mt="sm" spacing="xs">
                <List.Item>
                    <Text mt="sm">
                        The Origami Company is not responsible for the loss or damage of any item (other than clothes or garments for cleaning services) that are included in the laundry bags (for example, 
                        any keys or earphones left in the pockets of any clothing).
                    </Text>
                </List.Item>
                <List.Item>
                    <Text mt="sm">
                        The Origami Company is not responsible for any normal wear and tear on clothing that may occur from the pickup, cleaning, and delivery process, including loosening or shrinkage, breakage 
                        of zippers or certain fasteners, loss of buttons, fading or stains, fatiguing, holes, or abrasions.
                    </Text>
                </List.Item>
                <List.Item>
                    <Text mt="sm">
                        The Origami Company is not responsible for any damage following your request that directly contradicts the label of the clothing.
                    </Text>
                </List.Item>
                <List.Item>
                    <Text mt="sm">
                        The Origami Company does not guarantee the removal of all stains from any clothing.
                    </Text>
                </List.Item>
                <List.Item>
                    <Text mt="sm">
                        The Origami Company is not responsible for the loss of any clothes.
                    </Text>
                </List.Item>
            </List>
            <Text mt="sm">
                In the event that the clothing items that you sent to The Origami Company for the Cleaning Services is damaged or lost due to the fault of The Origami Company (including our employees), we will work with you and endeavor to rectify the situation, provided that you send us a written notification identifying the issues with your order of the Cleaning Services with supporting evidence within fourteen (14) days of our delivery of the cleaned items for such order. Under The Origami Company’s sole discretion, we may reimburse you up to the full value of an item, however, such reimbursement will not exceed $200 per clothing item, or $500 per order (for either Wash & Fold or Dry Cleaning).
            </Text>
            <Text mt="sm">
                Notwithstanding anything else, you understand and agree that any warranty or promise for reimbursement by The Origami Company will be secondary to any applicable warranty offered by the clothing item manufacturer (for example, if a damaged clothing item is covered by Lululemon’s warranty policy, we require that you reach out to Lululemon first (and provide written evidence of your communication), prior to reaching out to The Origami Company in making a request for reimbursement)).
            </Text>

            <Text mt="sm">
                TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE THE ORIGAMI COMPANY PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS, PRODUCTS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF FIVE-HUNDRED ($500) DOLLARS, (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
            </Text>

            <Text mt="sm" fw={500}>4) Indemnity</Text>
            <Text mt="sm">
                You agree to indemnify and hold the The Origami Company Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of these Terms. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).
            </Text>

            <Text mt="sm" fw={500}>5) Assignment</Text>
            <Text mt="sm">
                You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without The Origami Company's prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.
            </Text>

            <Text mt="sm" fw={500}>6) Choice of Law</Text>
            <Text mt="sm">
                These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of the State of California, without regard to the conflicts of laws provisions thereof.
            </Text>

            <Text mt="sm" fw={500}>7) Arbitration Agreement</Text>
            <Text mt="sm">
                Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and claims with The Origami Company and limits the manner in which you can seek relief from The Origami Company.
            </Text>
            <Text mt="sm">
                Both you and The Origami Company acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject matter of these Terms and/or Services, The Origami Company's officers, directors, employees and independent contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.
            </Text>
            <Text mt="sm">
                (a) Arbitration Rules; Applicability of Arbitration Agreement. The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these Terms and/or Services directly through good-faith negotiations, which shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration in Harris County, Texas or, if the JAMS Consumer Arbitration Minimum Standards are applicable, near or in the county in which you reside. The arbitration will proceed in the English language, in accordance with the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent jurisdiction. The JAMS Consumer Arbitration Minimum Standards shall apply as required by JAMS. The JAMS Optional Arbitration Appeal Procedures shall apply to disputes and claims having a value of $250,000 or greater.
            </Text>
            <Text mt="sm">
                (b) Small Claims Court; Infringement. Either you or The Origami Company may assert claims, if they qualify, in small claims court in Harris County, Texas or any United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.
            </Text>
            <Text mt="sm">
                (c) Waiver of Jury Trial. YOU AND THE ORIGAMI COMPANY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and The Origami Company are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any litigation between you and The Origami Company over whether to vacate or enforce an arbitration award, YOU AND THE ORIGAMI COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.
            </Text>
            <Text mt="sm">
                (d) Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor The Origami Company is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in (g) below.
            </Text>
            <Text mt="sm">
                (e) Opt-out. You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the following address: 12670 Jebbia Ln Stafford, TX 77477 postmarked within thirty (30) days of first accepting these Terms. You must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out of these Terms’ arbitration agreement.
            </Text>
            <Text mt="sm">
                (f) Exclusive Venue. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits either you or The Origami Company to litigate any dispute arising out of or relating to the subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either party, and both you and The Origami Company agree that any judicial proceeding (other than small claims actions) will be brought in the state or federal courts located in, respectively, Harris County, Texas, or the federal district in which that county falls.
            </Text>
            <Text mt="sm">
                (g) Severability. If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This arbitration agreement will survive the termination of your relationship with The Origami Company.
            </Text>
            
            <Text mt="sm" fw={500}>8) Miscellaneous</Text>
            <Text mt="sm">
                You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that the The Origami Company may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and The Origami Company agree that these Terms are the complete and exclusive statement of the mutual understanding between you and The Origami Company, and that these Terms supersede and cancel all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of The Origami Company, and you do not have any authority of any kind to bind The Origami Company in any respect whatsoever.
            </Text>
        </Box>
        <Divider my="lg" />

        <Box>
            <Text mt="sm">
                Except as expressly set forth in the sections above regarding the Apple Application and the arbitration agreement, you and The Origami Company agree there are no third-party beneficiaries intended under these Terms.
            </Text>

        </Box>


        </Container>
        <DefaultFooter />
    </>
  );
};

export default TermsOfUse;