import React from "react";
import {
  Container,
  Title,
  Text,
  ThemeIcon,
  Stepper,
  Box,
  Stack,
} from "@mantine/core";
import {
  IconUserPlus,
  IconTruckDelivery,
  IconBasket,
  IconDoor,
  IconTimeline,
  IconPackage,
} from "@tabler/icons-react";
import DefaultFooter from "../../components/Footers/DefaultFooter";

const steps = [
  {
    title: "Sign up",
    description: "Takes 3 minutes",
    icon: <IconUserPlus size={24} />,
  },
  {
    title: "Receive Hamper",
    description: "For free!",
    icon: <IconTruckDelivery size={24} />,
  },
  {
    title: "Fill Hamper",
    description: "Add all the laundry you can fit in your hamper",
    icon: <IconBasket size={24} />,
  },
  {
    title: "Pickup",
    description: "Our driver will pick it up from outside your door on your pick up days",
    icon: <IconDoor size={24} />,
  },
  {
    title: "Track",
    description: "Monitor the progress of your laundry",
    icon: <IconTimeline size={24} />,
  },
  {
    title: "Drop off",
    description: "Washed and folded laundry is returned within 24hrs",
    icon: <IconPackage size={24} />,
  },
];

const HowItWorks = () => {
  return (
    <Container size="xs" my="xl" id="how-it-works">
      <Title align="center" mb="xl">
        How It Works
      </Title>
      <Stepper active={-1} orientation="vertical">
        {steps.map((step, index) => (
          <Stepper.Step
            key={index}
            label={step.title}
            description={step.description}
            icon={
              <ThemeIcon size={40} radius="xl" variant="light" color="blue">
                {step.icon}
              </ThemeIcon>
            }
          >
            <Box p="md">
              <Text size="sm" color="dimmed">
                {step.description}
              </Text>
            </Box>
          </Stepper.Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default HowItWorks;