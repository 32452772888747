import React from 'react';
import Aux from "../../Aux/Aux";
import { Timeline, Text } from '@mantine/core';
import {
    IconTruck,
    IconBuildingStore,
    IconWash,
    IconHanger,
    IconWashTumbleDry,
    IconSock,
} from '@tabler/icons-react';
import { IconGitBranch, IconGitPullRequest, IconGitCommit, IconMessageDots } from '@tabler/icons-react';

const TrackingTable = (props) => {
    const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        },
        atLaundromatDetails = props.details[ props.details.length - 2 ],
        laundromatDate = atLaundromatDetails?.statusUpdatedAt,
        atLaundromatDate = new Date( laundromatDate );

    let washingDate = new Date( atLaundromatDate ),
        dryingDate = new Date( atLaundromatDate ),
        foldingDate = new Date( atLaundromatDate );

    if( laundromatDate && atLaundromatDate?.toString() !== 'Invalid Date') {
        washingDate.setHours( atLaundromatDate.getHours() + 2 );
        dryingDate.setHours( atLaundromatDate.getHours() + 4 );
        foldingDate.setHours( atLaundromatDate.getHours() + 6 );
    }

    let rows = [...props.details].reverse().map((item, index) => (
        <Aux>
            {item.status === "PICKED_UP_FROM_CUSTOMER" &&
                <Timeline.Item bullet={<IconTruck size={24} />} title="Picked Up">
                    <Text color="dimmed" size="md">
                        {(new Date(item.statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                    </Text>
                </Timeline.Item>
            }

            {item.status === "DROPPED_OFF_AT_LAUNDROMAT" &&
                <Timeline.Item bullet={<IconBuildingStore size={24} />} title="Delivered to Laundromat">
                    <Text color="dimmed" size="md">
                        {(new Date(item.statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                    </Text>
                </Timeline.Item>
            }
            {item.status === "PICKED_UP_FROM_LAUNDROMAT" &&
                <Timeline.Item bullet={<IconTruck size={24} />} title="In Route back to you">
                    <Text color="dimmed" size="md">
                        {(new Date(item.statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                    </Text>
                </Timeline.Item>
            }
            {item.status === "DELIVERED_TO_CUSTOMER" &&
                <Timeline.Item bullet={<IconHanger size={24} />} title="Delivered">
                    <Text color="dimmed" size="md">
                        {(new Date(item.statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                    </Text>
                </Timeline.Item>
            }

            {index === (props.details.length - 2) && 5 <= props.active && laundromatDate &&
                <Timeline.Item bullet={<IconSock size={24} />} title="Folding">
                    <Text color="dimmed" size="md">
                        {foldingDate.toLocaleString('en-US', options).replace('at', '')}
                    </Text>
                </Timeline.Item>
            }
            {index === (props.details.length - 2) && 4 <= props.active && laundromatDate &&
                <Timeline.Item bullet={<IconWashTumbleDry size={24} />} title="Drying">
                    <Text color="dimmed" size="md">
                        {dryingDate.toLocaleString('en-US', options).replace('at', '')}
                    </Text>
                </Timeline.Item>
            }
            {index === (props.details.length - 2) && 3 <= props.active && laundromatDate &&
                <Timeline.Item bullet={<IconWash size={24} />} title="Washing">
                    <Text color="dimmed" size="md">
                        {washingDate.toLocaleString('en-US', options).replace('at', '')}
                    </Text>
                </Timeline.Item>
            }
        </Aux>
    ))

    return (
        <Aux>
            <Timeline active={props.active} bulletSize={36} lineWidth={3}>
                {props.details.length >= 1 &&
                    <Timeline.Item bullet={<IconTruck size={24} />} title="Picked Up">
                        <Text color="dimmed" size="md">
                            {(new Date(props.details[0].statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                        </Text>
                    </Timeline.Item>
                }
                {props.details.length >= 2 &&
                    <Timeline.Item bullet={<IconBuildingStore size={24} />} title="Delivered to Laundromat">
                        <Text color="dimmed" size="md">
                            {(new Date(props.details[1].statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                        </Text>
                    </Timeline.Item>
                }
                {props.details.length >= 2 && 3 <= props.active && laundromatDate &&
                    <Timeline.Item bullet={<IconWash size={24} />} title="Washing">
                        <Text color="dimmed" size="md">
                            {washingDate.toLocaleString('en-US', options).replace('at', '')}
                        </Text>
                    </Timeline.Item>
                }
                {props.details.length >= 2 && 4 <= props.active && laundromatDate &&
                    <Timeline.Item bullet={<IconWashTumbleDry size={24} />} title="Drying">
                        <Text color="dimmed" size="md">
                            {dryingDate.toLocaleString('en-US', options).replace('at', '')}
                        </Text>
                    </Timeline.Item>
                }
                {props.details.length >= 2 && 5 <= props.active && laundromatDate &&
                    <Timeline.Item bullet={<IconSock size={24} />} title="Folding">
                        <Text color="dimmed" size="md">
                            {foldingDate.toLocaleString('en-US', options).replace('at', '')}
                        </Text>
                    </Timeline.Item>
                }
                {props.details.length >= 3 &&
                    <Timeline.Item bullet={<IconTruck size={24} />} title="In Route back to you">
                        <Text color="dimmed" size="md">
                            {(new Date(props.details[2].statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                        </Text>
                    </Timeline.Item>
                }
                {props.details.length >= 4 &&
                    <Timeline.Item bullet={<IconHanger size={24} />} title="Delivered">
                        <Text color="dimmed" size="md">
                            {(new Date(props.details[3].statusUpdatedAt)).toLocaleString('en-US', options).replace('at', '')}
                        </Text>
                    </Timeline.Item>
                }
            </Timeline>

        </Aux>
    );
};

export default TrackingTable;
