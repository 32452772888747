import React from "react";
import styled from "styled-components"
import Button from "../../components/Form/Button/v1/Button";
import Aux from "../../components/Aux/Aux";
import {useNavigate} from "react-router-dom";
import PageTitle from "../../components/utility/text/PageTitle";
import PageContainer from "../../components/utility/containers/PageContainer";
import PageWidthContainer from "../../components/utility/containers/PageWidthContainer";
import CustomerHero from "../../assets/images/background/Customer_Portal.png";
import PageSubheader from "../../components/utility/text/PageSubheader";
import TeaserCard from "../../components/utility/containers/TeaserCard";
import Image from "../../components/Image/v1/Image";

const EmployeeHome = (props) => {
    const navigate = useNavigate();

    return (
        <Aux>
            { props.screenType === "Mobile" &&
                <Aux>
                    <PageWidthContainer
                        width={100}
                        height={"200px"}
                        display={true}
                    >
                        <Image
                            cssUrl={CustomerHero}
                            height={"100%"}
                        />
                    </PageWidthContainer>
                    <MarginTop>
                        <PageTitle margin={"none"} alignment={"center"}>Employee Dashboard</PageTitle>
                        <PageSubheader alignment={"center"}>Your one stop laundry shop.</PageSubheader>
                    </MarginTop>
                </Aux>
            }
            { props.screenType !== "Mobile" &&
                <PageWidthContainer
                    width={100}
                    height={"333px"}
                    display={true}
                >
                    <Image
                        cssUrl={CustomerHero}
                        height={"333px"}
                    />
                    <TeaserCard>
                        <PageTitle margin={"none"} alignment={"center"}>Employee Dashboard</PageTitle>
                        <PageSubheader alignment={"center"}>Your one stop laundry shop.</PageSubheader>
                    </TeaserCard>
                </PageWidthContainer>
            }
            <PageContainer>
                <ButtonParent>
                    <Button submit={() => navigate("/employee/update-tracking")}>Update Hamper</Button>
                    <Button inverse={true} submit={() => navigate("/employee/address-list")}>Delivery List</Button>
                </ButtonParent>
            </PageContainer>
        </Aux>
    )
}

export default EmployeeHome;

const MarginTop = styled.div`
    margin-top: 20px;
`;

const ButtonParent = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-content: center;

    & > div {
        width: 33%;
    }

    @media (max-width: 768px) {
        display: block;

        & > div {
            width: 100%;
        }
    }
`;
