import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Title,
  Text,
  Stack,
  Button,
  Group,
  Anchor,
  PinInput,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import {
  removeEmailSuccess,
  removePhoneSuccess,
  sendEmailVerification,
  sendPhoneVerification,
  verifyEmailCode,
  verifyPhoneCode,
} from "../../store/reducers/customer";
import Spinner from "../../components/Spinner/v1/Spinner";
import SuccessVerification from "../../components/Verification/SuccessVerification";

const CustomerValidateV2 = (props) => {
  const dispatch = useDispatch();
  const emailVerified = useSelector((state) => state.customer.isEmailVerified);
  const phoneVerified = useSelector((state) => state.customer.isPhoneNumberVerified);
  const showEmailSuccess = useSelector((state) => state.customer.showEmailSuccess);
  const showPhoneSuccess = useSelector((state) => state.customer.showPhoneSuccess);
  const emailAddress = useSelector((state) => state.customer.emailAddress);
  const phoneNumber = useSelector((state) => state.customer.phoneNumber);
  const [emailVerifyError, setEmailVerifyError] = useState("");
  const [phoneVerifyError, setPhoneVerifyError] = useState("");

  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const handleInputChange = (value) => {
    const numericValue = value.replace(/[^0-9]/g, ""); // Allow only numeric values
    setVerificationCode(numericValue);
  };

  const handleVerifyEmail = () => {
    if (verificationCode.trim().length === 6) {
      const verifyEmail = async () => {
        await dispatch(
            verifyEmailCode({
              body: { verificationCode: verificationCode },
              setLoading: setLoading,
              setError: setEmailVerifyError,
            })
          );
      }
      
      setLoading(true);
      setTimeout(function() {
        verifyEmail()
      }, 10);
    }
  };

  const handleVerifyPhone = () => {
    if (verificationCode.trim().length === 6) {
        const verifyPhone = async () => {
          await dispatch(
              verifyPhoneCode({
                body: { verificationCode: verificationCode },
                setLoading: setLoading,
                setError: setPhoneVerifyError,
              })
            );
        }
        
        setLoading(true);
        setTimeout(function() {
          verifyPhone()
        }, 10);
    }
  };

  const handleResendEmail = () => {
    const sendEmailVerifyCode = async () => {
        await dispatch(
            sendEmailVerification({
                setLoading: setLoading,
            })
        )
    }

    setLoading(true);
    setTimeout(function() {
        sendEmailVerifyCode()
    }, 10);
  };

  const handleResendPhone = () => {
    const sendPhoneVerifyCode = async () => {
        await dispatch(
            sendPhoneVerification({
                setLoading: setLoading,
            })
        )
    }

    setLoading(true);
    setTimeout(function() {
        sendPhoneVerifyCode()
    }, 10);
  };

  useEffect(() => {
    if (emailVerified && phoneVerified && !showPhoneSuccess && !showEmailSuccess) {
      props.nextStep();
    }
  }, [emailVerified, phoneVerified, showPhoneSuccess, showEmailSuccess, props]);

  return (
    <>
      {loading && <Spinner />}
      {!emailVerified && (
        <Container size="xs" my="xl">
          <Paper withBorder shadow="md" radius="md" p="lg">
            <Stack spacing="md" align="center">
              <Title order={3}>Email Address Verification</Title>
              <Text size="sm" color="dimmed" align="center">
                Enter the 6-digit verification code that was sent to your email address.
              </Text>
              <PinInput
                length={6}
                size="lg"
                value={verificationCode}
                onChange={handleInputChange}
                autoFocus
                defaultValue=""
              />
              <Button fullWidth mt="sm" onClick={handleVerifyEmail}>
                Verify Account
              </Button>
              <Group position="center" spacing="xs">
                <Text size="sm" color="dimmed">
                  Didn't receive code?
                </Text>
                <Anchor component="button" size="sm" color="blue" onClick={handleResendEmail}>
                  Resend
                </Anchor>
              </Group>
            </Stack>
          </Paper>
        </Container>
      )}
      {emailVerified && !showEmailSuccess && !phoneVerified && (
        <Container size="xs" my="xl">
          <Paper withBorder shadow="md" radius="md" p="lg">
            <Stack spacing="md" align="center">
              <Title order={3}>Phone Number Verification</Title>
              <Text size="sm" color="dimmed" align="center">
                Enter the 6-digit verification code that was sent to your phone number.
              </Text>
              <PinInput
                length={6}
                size="lg"
                value={verificationCode}
                onChange={handleInputChange}
                autoFocus
                defaultValue=""
              />
              <Button fullWidth mt="sm" onClick={handleVerifyPhone}>
                Verify Account
              </Button>
              <Group position="center" spacing="xs">
                <Text size="sm" color="dimmed">
                  Didn't receive code?
                </Text>
                <Anchor component="button" size="sm" color="blue" onClick={handleResendPhone}>
                  Resend
                </Anchor>
              </Group>
            </Stack>
          </Paper>
        </Container>
      )}
    </>
  );
};

export default CustomerValidateV2;