import React from "react";
import { Loader, Center } from "@mantine/core";

const Spinner = () => {
  return (
    <Center
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000000000,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
    >
      <Loader size="xl" color="blue" />
    </Center>
  );
};

export default Spinner;