import React from 'react';
import { Button, Container, Text, Title } from '@mantine/core';
import classes from './Locations.module.css';
import DefaultFooter from '../../components/Footers/DefaultFooter';
import { useNavigate } from 'react-router-dom';
import {useSelector} from "react-redux";

const Locations = () => {
  const isAuthenticated = useSelector( (state) => state.auth.isAuthenticated )
  const role = useSelector( (state) => state.auth.role );
  const redirectUri = isAuthenticated ? `/${role?.toLowerCase()}` : "/signup";
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate(redirectUri);
  };

  return (
      <>
        <div className={classes.locationsRoot}>
          <Container size="lg">
            <div className={classes.locationsInner}>
              <div className={classes.locationsContent}>
                <Title className={classes.locationsTitle}>
                  Get ready{' '}
                  <Text
                      component="span"
                      inherit
                      className={classes.locationsHouston}
                  >
                    Houston
                  </Text>{''}
                  !
                </Title>

                <Text className={classes.locationsDescription} mt={30}>
                  Welcome to The Origami Company, where laundry day becomes a thing of the past. We’re excited to announce that we’ve launched our first service location in the vibrant city of Houston, Texas!
                </Text>

                <Button
                    size="xl"
                    className={classes.locationsControl}
                    mt={40}
                    onClick={handleSignup}
                >
                  Get started
                </Button>
              </div>
            </div>
          </Container>
        </div>
        <DefaultFooter />
      </>
  );
}

export default Locations;