import React, {useEffect, useRef, useState} from 'react';
import Aux from "../../components/Aux/Aux";
import PageContainer from "../../components/utility/containers/PageContainer";
import PageTitle from "../../components/utility/text/PageTitle";
import styled from "styled-components";
import QRCodeScanner from "../../components/QRCodeScanner/v1/QRCodeScanner";
import {useDispatch, useSelector} from "react-redux";
import {tracking} from "../../store/reducers/hamper";
import FormContainer from "../../components/Form/Container/v1/FormContainer";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import {Card, CardParent} from "../../components/utility/containers/Card";
import QRImg from "../../assets/images/images/QR.png";
import PageSubheader from "../../components/utility/text/PageSubheader";
import Input from "../../components/Form/Input/v1/Input";
import Image from "../../components/Image/v1/Image";
import Spinner from "../../components/Spinner/v1/Spinner";
import Back from "../../components/Back/Back";

const EmployeeTracking = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("PICKED_UP_FROM_CUSTOMER");
    const [formData, setFormData] = useState("")
    const [formError, setFormError] = useState(false);
    const [codeIndex, setCodeIndex] = useState(-1);
    const inputRef = useRef();
    const selectRef = useRef();
    const error = useSelector( (state) => state.hamper.error );
    const successMessage = useSelector( (state) => state.hamper.successMessage );

    const setError = () => {
        setFormError( true );
    }

    const clearError = () => {
        setFormError( false );
    }

    const handleKeyPress = (event) => {
        if (event.key.length === 1 && (
            (event.key >= 'a' && event.key <= 'z') ||
            (event.key >= 'A' && event.key <= 'Z') ||
            (event.key >= '0' && event.key <= '9')) ) {
            let data = "";
            setFormData(prevState => {
                data = prevState + event.key;

                if( /^[A-Za-z0-9]{5}$/.test( data ) ) {
                    handleScanResult( data )
                }

                setCodeIndex( data.length );

                return data.toUpperCase()
            });
        }
        else if( event.key === 'Backspace' ) {
            setFormData(prevState => prevState.substring(0, prevState.length - 1) );
            setCodeIndex( prevState => {
                if( prevState > 0 ) {
                    return (prevState - 1)
                }
                return prevState
            });
        }
    };

    const handleClick = () => {
        let idx = formData.length - 1;
        if( idx < 0 ) {
            idx = 0;
        }
        setCodeIndex( idx );
        inputRef.current.querySelector('input').click();
        inputRef.current.querySelector('input').focus();
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, []);

    const handleScanResult = (data) => {
        const body = {
            body: {
                hamperCode: data?.toUpperCase(),
                status: selectRef?.current?.value || ""
            },
            setLoading: setLoading,
            setFormData: setFormData,
            setCodeIndex: setCodeIndex
        }
        setLoading( true );
        setFormData( "" );
        setCodeIndex( -1 );
        dispatch( tracking(body) );
    }

    const handleStatusChange = (event) => {
        setStatus( prevState => event.target.value )
    }

    return (
        <Aux>
            { loading && <Spinner />}
            <Back to={"/employee"} />
            <PageContainer>
                <CardParent>
                    <Card>
                        <UpdatePageTitle>
                            <PageTitle>Update Hamper Status</PageTitle>
                        </UpdatePageTitle>
                        <FormContainer errorMessage={error} successMessage={successMessage}>
                            <PageSubheader alignment={"center"} ><b>Hamper Status</b></PageSubheader>
                            <DropdownContainer>
                                <TrackingDropdown onChange={handleStatusChange} ref={selectRef}>
                                    <option value={"PICKED_UP_FROM_CUSTOMER"}>Picked Up</option>
                                    <option value={"DROPPED_OFF_AT_LAUNDROMAT"}>At Laundromat</option>
                                    <option value={"PICKED_UP_FROM_LAUNDROMAT"}>In Route to Drop Off</option>
                                    <option value={"DELIVERED_TO_CUSTOMER"}>Delivered</option>
                                </TrackingDropdown>
                            </DropdownContainer>
                            <Relative>
                                <Hidden ref={inputRef} >
                                    <Input
                                        name={"hamperCode"}
                                        type={"text"}
                                        value={formData}
                                        screenType={props.screenType}
                                        hasError={formError}
                                        setError={setError}
                                        clearError={clearError}
                                    />
                                </Hidden>
                            </Relative>
                            <PageSubheader alignment={"center"} ><b>Hamper Code</b></PageSubheader>
                            <CodeBoxParent>
                                <CodeBox active={codeIndex === 0} code={formData.charAt(0)} onClick={handleClick}/>
                                <CodeBox active={codeIndex === 1} code={formData.charAt(1)} onClick={handleClick}/>
                                <CodeBox active={codeIndex === 2} code={formData.charAt(2)} onClick={handleClick}/>
                                <CodeBox active={codeIndex === 3} code={formData.charAt(3)} onClick={handleClick}/>
                                <CodeBox active={codeIndex === 4} code={formData.charAt(4)} onClick={handleClick}/>
                            </CodeBoxParent>
                            <DividerParent>
                                <DividerLine />
                                <DividerText>OR</DividerText>
                                <DividerLine />
                            </DividerParent>

                            <ImageParent>
                                <Image url={QRImg}/>
                            </ImageParent>
                            <QRCodeScanner handleScanResult={handleScanResult} />
                        </FormContainer>
                    </Card>
                </CardParent>
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent}/>
        </Aux>
    );
};

export default EmployeeTracking;

const DropdownContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TrackingDropdown = styled.select`
    width: 80%;
    text-align: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    font-size: 24px;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: black;
    color: white;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220%200%2024%2024%22%3E%3Cpath fill%3D%22none%22 d%3D%22M0%200h24v24H0z%22/%3E%3Cpath fill%3D%22white%22 d%3D%22M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 13.5L7.5 11l1.42-1.41L12 12.67l3.08-3.08L16.5 11 12 15.5z%22/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 25px;
    font-family: 'Roboto';
    font-weight: 700;
    margin: 10px 0;
    text-align-last: center;
    -moz-text-align-last: center;
    -ms-text-align-last: center;
    
    & option {
        text-align: center;
        text-align-last: center;
        -moz-text-align-last: center;
        -ms-text-align-last: center;
    }
    
    @media (min-width: 768px) {
        width: 100%;
        font-size: 16px;
    }
`;

const UpdatePageTitle = styled.div`
    & h1 {
        margin: 0 !important;
    }
`;

const Relative = styled.div`
    position: relative;
`;

const ImageParent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        width: 120px;
    }
`;

const DividerParent = styled.div`
    width: 100%;
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-direction: row;
`;

const DividerText = styled.div`
    width: 33%;
    color: #212121;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
`;

const DividerLine = styled.div`
    width: 33%;
    height: 1px;
    background-color: #d3d3d3;
    border-radius: 2px;
`;

const Hidden = styled.div`
    position: absolute;
    top: 0;
    opacity: 0;
    pointer-events: none;
`;

const CodeBoxParent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CodeBox = styled.div`
    display: inline-block;
    width: 70px;
    height: 70px;
    padding: 10px;
    margin: 20px;
    border: ${props => props.active ? "1px solid #212121" : "none"};
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.16);
    color: #212121;
    font-size: 16px;
    line-height: 49px;
    outline: none;
    background-color: transparent;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #212121;
        width: ${props => props.active ? "2px" : 0 };
        height: ${props => props.active ? "70%" : 0 };

        animation: blink 1s step-start infinite;

        @keyframes blink {
            0%, 100% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
        }
    }

    &:after {
        content: "${props => props.code }";
        position: absolute;
        font-size: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media (max-width: 768px) {
        width: 55px;
        height: 55px;
        padding: 5px;
        margin: 5px;

        &:after {
            font-size: 30px;
        }
    }

    @media (max-width: 500px) {
        width: 45px;
        height: 45px;
    }
`;
