import { IconBrandInstagram, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';
import {
  ActionIcon,
  Button,
  Paper,
  SimpleGrid,
  Text,
  Textarea,
  TextInput,
  Title,
  Group,
} from '@mantine/core';
import { ContactIconsList } from './ContactIcons';
import classes from './ContactUs.module.css';
import Aux from "../../components/Aux/Aux";
import DefaultFooter from "../../components/Footers/DefaultFooter";

const social = [IconBrandTwitter, IconBrandYoutube, IconBrandInstagram];

export function ContactUs() {
  const icons = social.map((Icon, index) => (
      <ActionIcon key={index} size={28} className={classes.contactUsSocial} variant="transparent">
        <Icon size={22} stroke={1.5} />
      </ActionIcon>
  ));

  return (
      <Aux>
          <div className={classes.contactUsOuterWrap}>
              <div className={classes.contactUsInnerWrap}>
                  <div className={classes.contactUsWrapper}>
                      <SimpleGrid
                          cols={2}
                          spacing="xl"
                          breakpoints={[{maxWidth: 768, cols: 1, spacing: 'md'}]} // Responsive behavior
                          className={classes.contactUsTable}
                      >
                          {/* Contact Info Section */}
                          <div>
                              <Title className={classes.contactUsTitle}>Contact us</Title>
                              <Text className={classes.contactUsDescription} mt="sm" mb={30}>
                                  Leave your email and we will get back to you within 24 hours
                              </Text>

                              <ContactIconsList/>

                              <Group mt="xl">{icons}</Group>
                          </div>

                          {/* Form Section */}
                          <Paper withBorder shadow="md" p={30} radius="md">
                              <TextInput
                                  label="Email"
                                  placeholder="your@email.com"
                                  required
                                  classNames={{
                                      input: classes.contactUsInput,
                                      label: classes.contactUsInputLabel,
                                  }}
                              />
                              <TextInput
                                  label="Name"
                                  placeholder="John Doe"
                                  mt="md"
                                  classNames={{
                                      input: classes.contactUsInput,
                                      label: classes.contactUsInputLabel,
                                  }}
                              />
                              <Textarea
                                  required
                                  label="Your message"
                                  placeholder="I want to order your goods"
                                  minRows={4}
                                  mt="md"
                                  classNames={{
                                      input: classes.contactUsInput,
                                      label: classes.contactUsInputLabel,
                                  }}
                              />
                              <Group justify="flex-end" mt="md">
                                  <Button className={classes.contactUsControl}>Send message</Button>
                              </Group>
                          </Paper>
                      </SimpleGrid>
                  </div>
              </div>
          </div>
          <DefaultFooter />
      </Aux>
  );
}