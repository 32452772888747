import React from "react";
import Aux from "../../components/Aux/Aux";
import PageTitle from "../../components/utility/text/PageTitle";
import PageContainer from "../../components/utility/containers/PageContainer";
import {useSelector} from "react-redux";
import styled from "styled-components";
import Button from "../../components/Form/Button/v1/Button";
import PageWidthContainer from "../../components/utility/containers/PageWidthContainer";
import Image from "../../components/Image/v1/Image";
import CustomerHero from "../../assets/images/background/Customer_Portal.png";
import TeaserCard from "../../components/utility/containers/TeaserCard";
import PageSubheader from "../../components/utility/text/PageSubheader";
import Subscribed from "../../assets/icons/Subscribed";
import WarningIcon from "../../assets/icons/WarningIcon";
import {useNavigate} from "react-router-dom";

const CustomerHome = (props) => {
    const navigate = useNavigate();
    const subscriptions = useSelector( (state) => state.customer.subscriptions );
    const allCanceled = (subscriptions.filter( subscription => subscription.status === "canceled").length === subscriptions.length);
    const hampers = useSelector( (state) => state.customer.hampers );
    const hamperCode = hampers?.[0]?.hamperCode;
    const firstName = useSelector( (state) => state.customer.firstname );
    const lastName = useSelector( (state) => state.customer.lastname );
    const email = useSelector( (state) => state.customer.emailAddress );
    const phone = useSelector( (state) => state.customer.phoneNumber );

    return (
        <Aux>
            { props.screenType === "Mobile" &&
                <Aux>
                    <PageWidthContainer
                        width={100}
                        height={"200px"}
                        display={true}
                    >
                        <Image
                            cssUrl={CustomerHero}
                            height={"100%"}
                        />
                    </PageWidthContainer>
                    <MarginTop>
                        <PageTitle margin={"none"} alignment={"center"}>Customer Dashboard</PageTitle>
                        <PageSubheader alignment={"center"}>Your one stop laundry shop.</PageSubheader>
                    </MarginTop>
                </Aux>
            }
            { props.screenType !== "Mobile" &&
                <PageWidthContainer
                    width={100}
                    height={"333px"}
                    display={true}
                >
                    <Image
                        cssUrl={CustomerHero}
                        height={"333px"}
                    />
                    <TeaserCard>
                        <PageTitle margin={"none"} alignment={"center"}>Customer Dashboard</PageTitle>
                        <PageSubheader alignment={"center"}>Your one stop laundry shop.</PageSubheader>
                    </TeaserCard>
                </PageWidthContainer>
            }
            <PageContainer>
                { !allCanceled &&
                    <GreenText>
                        <SubscriptionNotice>
                            <PageSubheader alignment={"center"}>
                                <Subscribed />
                                Subscription Active
                            </PageSubheader>
                        </SubscriptionNotice>
                    </GreenText>
                }
                { allCanceled &&
                    <WarningText>
                        <SubscriptionNotice>
                            <PageSubheader alignment={"center"}>
                                <WarningIcon />
                                Subscription Inactive
                            </PageSubheader>
                        </SubscriptionNotice>
                    </WarningText>
                }
                <PageTitle margin={"none"} alignment={"center"}>{firstName} {lastName}</PageTitle>
                <PageSubheader alignment={"center"}>
                    {email} <br/>
                    {phone}
                </PageSubheader>
                <ButtonParent>
                    <Button submit={() => navigate("/tracking/" + hamperCode)}>Hamper Tracking</Button>
                    <Button inverse={true} submit={() => navigate("/customer/profile")}>Edit Profile</Button>
                    <Button inverse={true} submit={() => navigate("/customer/subscription")}>Manage Subscription</Button>
                </ButtonParent>
            </PageContainer>
        </Aux>
    )
}

export default CustomerHome;

const MarginTop = styled.div`
    margin-top: 20px;
`;

const ButtonParent = styled.div`
    display: flex;
    gap: 10px;
    
    & > div {
        width: 33%;
    }
    
    @media (max-width: 768px) {
        display: block;

        & > div {
            width: 100%;
        }
    }
`;

const SubscriptionNotice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    & p {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px
    }
    
    @media (max-width: 768px) {
        & p {
            font-size: 15px
        }
    }
    
    & svg {
        margin-right: 15px;
        width: 35px;
        height: 35px;
        
        @media (max-width: 768px) {
            width: 20px;
            height: 20px;
        }
        
        & svg {
            fill: #ffffff;
            color: #ffffff;
        }
    }
`;

const GreenText = styled.div`
    & p {
        color: #4cd964 !important;
    }
    
    & svg {
        fill: #4cd964;
        color: #4cd964;
    }
`;

const WarningText = styled.div`
    & p {
        color: #FFCC00 !important;
    }
    
    & svg {
        fill: #FFCC00;
        color: #FFCC00;
    }
`;
