import { Button, Container, Group, Text, Title } from '@mantine/core';
import {useNavigate} from "react-router-dom";
import classes from './NotFound.module.css';

const NotFound = () => {
    const navigate = useNavigate();

  return (
    <Container className={classes.notFoundRoot}>
      <div className={classes.notFoundLabel}>404</div>
      <Title className={classes.notFoundTitle}>You have found a secret place.</Title>
      <Text color="dimmed" size="lg" align="center" className={classes.notFoundDescription}>
        Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
        been moved to another URL.
      </Text>
      <Group position="center">
        <Button variant="subtle" size="md" onClick={() => navigate("/")}>
          Take me back to home page
        </Button>
      </Group>
    </Container>
  );
}

export default NotFound;