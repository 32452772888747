import React, { useState } from "react";
import { IMaskInput } from "react-imask";
import {
  Title,
  Container,
  Stepper,
  Button,
  Group,
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Checkbox,
  Anchor,
  InputBase,
  Select,
  Box,
  LoadingOverlay,
  Progress,
  Center,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { signup, usernameValidation, addressValidation } from "../../store/reducers/signup";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import "./CompanySignup.css"

const requirements = [
    { re: /[0-9]/, label: "Includes number" },
    { re: /[a-z]/, label: "Includes lowercase letter" },
    { re: /[A-Z]/, label: "Includes uppercase letter" },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
];

const getStrength = (password) => {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
        multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
};
  
const PasswordRequirement = ({ meets, label }) => (
    <Text component="div" color={meets ? "teal" : "red"} mt={5} size="sm">
        <Center inline>
        {meets ? <IconCheck size={14} stroke={1.5} /> : <IconX size={14} stroke={1.5} />}
        <Box ml={7}>{label}</Box>
        </Center>
    </Text>
);

const CompanySignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const usernameError = useSelector( (state) => state.signup.usernameError );
  const addressError = useSelector( (state) => state.signup.addressError );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailAddress: "",
    phoneNumber: "",
    emailAddressConsent: true,
    phoneNumberConsent: true,
    agreeToTerms: false,
    password: "",
    confirmPassword: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "USA",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear errors dynamically as the user types
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const validateStep = () => {
    const newErrors = {};
    if (activeStep === 0) {
      if (!formData.firstname.trim()) newErrors.firstname = "First name is required";
      if (!formData.lastname.trim()) newErrors.lastname = "Last name is required";
      if (!formData.emailAddress.trim()) newErrors.emailAddress = "Email address is unavailable";
      if (!formData.phoneNumber.trim()) newErrors.phoneNumber = "Phone number is unavailable";
      if (!formData.agreeToTerms) newErrors.agreeToTerms = "You must agree to the terms and conditions"; // Validation for checkbox
    } else if (activeStep === 1) {
      if (!formData.addressLine1.trim()) newErrors.addressLine1 = "Address Line 1 is required";
      if (!formData.addressLine2.trim()) newErrors.addressLine2 = "Address Line 2 is required";
      if (!formData.city.trim()) newErrors.city = "City is required";
      if (!formData.state.trim()) newErrors.state = "State is required";
      if (!formData.zipCode.trim()) newErrors.zipCode = "Zip Code is required";
    } else if (activeStep === 2) {
      if (!formData.password.trim()) newErrors.password = "Password is required";
      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = "Passwords do not match";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleNext = async () => {
    if (!validateStep()) return; // Validate the current step before proceeding
  
    setIsLoading(true); // Show the loading spinner
  
    setTimeout( function() {
        if (activeStep === 0) {
        // Step 1: Validate email and phone number
            const validateUsername = async () => {
                await dispatch(
                    usernameValidation({
                        body: {
                        emailAddress: formData.emailAddress,
                        phoneNumber: formData.phoneNumber,
                        },
                        setStep: setActiveStep,
                        nextStep: activeStep + 1,
                        loading: setIsLoading,
                    })
                );
            }
            validateUsername();
        } else if (activeStep === 1) {
        // Step 2: Validate address details
            const validateAddress = async () => {
                await dispatch(
                addressValidation({
                    body: {
                    addressLine1: formData.addressLine1,
                    addressLine2: formData.addressLine2,
                    city: formData.city,
                    state: formData.state,
                    zipCode: formData.zipCode,
                    country: formData.country,
                    },
                    setStep: setActiveStep,
                    nextStep: activeStep + 1,
                    loading: setIsLoading
                })
                );
            }
            validateAddress();
        } else {
        // Step 3: No further validation, allow navigation
        setActiveStep((activeStep) => activeStep + 1);
        setIsLoading(false);
        }
    }, 500);
  };

  const handleSubmit = async () => {
    if (!validateStep()) return;

    setIsLoading(true);
    await dispatch(signup({ body: formData, setLoading: setIsLoading }));
    setIsLoading(false);
  };

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  const strength = getStrength(formData.password);
  const passwordChecks = requirements.map((req, index) => (
    <PasswordRequirement key={index} label={req.label} meets={req.re.test(formData.password)} />
  ));
  const passwordBars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        value={
          formData.password.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  return (
    <Container size="xs" my="xl">
        <Title align="center">
            Create an account
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
            Already have an account?{' '}
            <Anchor size="sm" component="button" onClick={() => navigate("/login")}>
            Login
            </Anchor>
        </Text>
        <Box pos="relative">
            <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Stepper
                    active={activeStep}
                    className={"stepperSignup"}
                    onStepClick={(step) => step <= activeStep && setActiveStep(step)}
                    mb="xl"
                >
                    <Stepper.Step/>
                    <Stepper.Step/>
                    <Stepper.Step/>
                    <Stepper.Completed>
                        <Text align="center">You have successfully completed the signup process!</Text>
                    </Stepper.Completed>
                </Stepper>

                {activeStep === 0 && (
                    <>
                        <TextInput
                            label="First Name"
                            placeholder="John"
                            required
                            value={formData.firstname}
                            onChange={(e) => handleChange("firstname", e.target.value)}
                            error={errors.firstname}
                            mt="lg"
                            mb="sm"
                        />
                        <TextInput
                            label="Last Name"
                            placeholder="Doe"
                            required
                            value={formData.lastname}
                            onChange={(e) => handleChange("lastname", e.target.value)}
                            error={errors.lastname}
                            mt="lg"
                            mb="sm"
                        />
                        <TextInput
                            label="Email"
                            placeholder="you@example.com"
                            required
                            value={formData.emailAddress}
                            onChange={(e) => handleChange("emailAddress", e.target.value)}
                            error={errors.emailAddress}
                            mt="lg"
                            mb="sm"
                        />
                        <InputBase
                            label="Phone Number"
                            component={IMaskInput}
                            mask="+1 (000) 000-0000" // Adjust this mask to your needs
                            placeholder="Your phone"
                            value={formData.phoneNumber}
                            onAccept={(value) => handleChange("phoneNumber", value)} // Update formData on input change
                            required
                            error={errors.phoneNumber}
                            mt="lg"
                            mb="sm"
                        />
                        <Group position="apart" mt="lg">
                            <Checkbox
                                label={
                                    <>
                                        I accept{' '}
                                        <Anchor href="/terms" target="_blank" inherit>
                                            terms and conditions
                                        </Anchor>
                                    </>
                                }
                                checked={formData.agreeToTerms}
                                onChange={(e) => handleChange("agreeToTerms", e.target.checked)}
                                error={errors.agreeToTerms}
                                mt="sm"
                            />
                        </Group>
                    </>
                )}

                {activeStep === 1 && (
                    <>
                        <TextInput
                            label="Street Address"
                            placeholder="123 Main St"
                            required
                            value={formData.addressLine1}
                            onChange={(e) => handleChange("addressLine1", e.target.value)}
                            error={errors.addressLine1}
                            mt="md"
                        />
                        <TextInput
                            label="Unit Number"
                            placeholder="Apt 12"
                            required
                            value={formData.addressLine2}
                            onChange={(e) => handleChange("addressLine2", e.target.value)}
                            error={errors.addressLine2}
                            mt="md"
                        />
                        <TextInput
                            label="City"
                            placeholder="Austin"
                            required
                            value={formData.city}
                            onChange={(e) => handleChange("city", e.target.value)}
                            error={errors.city}
                            mt="md"
                        />
                        <Select
                            label="State"
                            placeholder=""
                            data={['TX']}
                            required
                            searchable
                            value={formData.state}
                            onChange={(value) => handleChange("state", value)} // Use the value directly
                            error={errors.state}
                            mt="md"
                        />
                        <TextInput
                            label="Zip Code"
                            placeholder="12345"
                            required
                            value={formData.zipCode}
                            onChange={(e) => handleChange("zipCode", e.target.value)}
                            error={errors.zipCode}
                            mt="md"
                        />
                    </>
                )}

                {activeStep === 2 && (
                    <>
                        <PasswordInput
                            label="Password"
                            placeholder="Enter your password"
                            value={formData.password}
                            onChange={(e) => handleChange("password", e.target.value)}
                            error={errors.password}
                            required
                            mt="md"
                        />
                        <Group gap={5} grow mt="xs" mb="md">
                            {passwordBars}
                        </Group>
                        <PasswordRequirement label="Has at least 6 characters" meets={formData.password.length > 5} />
                        {passwordChecks}
                        <PasswordInput
                            label="Confirm Password"
                            placeholder="Confirm your password"
                            value={formData.confirmPassword}
                            onChange={(e) => handleChange("confirmPassword", e.target.value)}
                            error={errors.confirmPassword}
                            required
                            mt="md"
                        />
                    </>
                )}

                {usernameError && (
                    <Text color="red" size="sm" mt="md" align="left">
                        {usernameError}
                    </Text>
                )}
                {addressError && (
                    <Text color="red" size="sm" mt="md" align="left">
                        {addressError}
                    </Text>
                )}

                <Group position="right" mt="xl">
                    {activeStep > 0 && <Button className={"signupButton"} onClick={handleBack}>Back</Button>}
                    {activeStep < 2 && <Button className={"signupButton"} onClick={handleNext}>Next</Button>}
                    {activeStep === 2 && <Button className={"signupButton"} onClick={handleSubmit}>Submit</Button>}
                </Group>
            </Paper>
        </Box>
    </Container>
  );
};

export default CompanySignUp;