import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Grid,
  Title,
  Divider,
  Text,
  Stack,
  Button,
  Group,
  Center,
  Anchor,
  Indicator,
} from "@mantine/core";
import { Calendar } from "@mantine/dates"; // Import Calendar correctly
import { IconArrowLeft } from "@tabler/icons-react";
import Spinner from "../../components/Spinner/v1/Spinner";
import DefaultFooter from "../../components/Footers/DefaultFooter";

const CustomerSubscription = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const customerInfo = useSelector((state) => state.customer);
  const role = useSelector((state) => state.auth.role);
  const dashboadUri = `/${role?.toLowerCase()}`;

  const allCanceled =
    customerInfo.subscriptions.filter(
      (subscription) => subscription.status === "canceled"
    ).length === customerInfo.subscriptions.length;

  const invoicePaidAt = new Date(customerInfo.latestInvoice.invoicePaidAt);
  const nextInvoicePaidAt = new Date(customerInfo.latestInvoice.invoicePaidAt);
  nextInvoicePaidAt.setMonth(nextInvoicePaidAt.getMonth() + 1); // Set the date to the next month
  const nextMonth = nextInvoicePaidAt.toLocaleString("en-US", { month: "long" });
  const year = nextInvoicePaidAt.getFullYear();

  const handleCheckoutPayment = () => {
    window.location.href = process.env.REACT_APP_CUSTOMER_CHECKOUT_PAYMENT;
  };

  const handlePortalPayment = () => {
    window.location.href = process.env.REACT_APP_CUSTOMER_UPDATE_PAYMENT;
  };

  return (
    <>
      {loading && <Spinner />}
      <Container size="lg" my="xl">
        <Anchor
          onClick={() => navigate(dashboadUri)}
          c="blue"
          size="sm"
          mb="lg"
          underline
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <IconArrowLeft size={16} style={{ marginRight: 8 }} />
          Back to dashboard
        </Anchor>
        <Grid gutter="lg">
          {/* Subscription Status */}
          <Grid.Col span={{ base: 12, xs: 4 }} md={6}>
            <Paper withBorder shadow="md" p="lg" radius="md">
              <Title order={3} mb="sm">
                Subscription Status
              </Title>
              <Divider mb="sm" />
              <Stack spacing="xs">
                <Group position="apart">
                  <Text>Status</Text>
                  <Text weight={700}>{allCanceled ? "Cancelled" : "Active"}</Text>
                </Group>
                <Group position="apart">
                  <Text>Pickup Date</Text>
                  <Text weight={700}>
                    {customerInfo.serviceSchedule?.pickUpDay || "N/A"}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>Dropoff Date</Text>
                  <Text weight={700}>
                    {customerInfo.serviceSchedule?.dropOffDay || "N/A"}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>Pickup Frequency</Text>
                  <Text weight={700}>
                    {customerInfo.serviceSchedule?.frequency || "N/A"}
                  </Text>
                </Group>
              </Stack>
            </Paper>
          </Grid.Col>

          {/* Payment Details */}
          <Grid.Col span={{ base: 12, xs: 4 }} md={6}>
            <Paper withBorder shadow="md" p="lg" radius="md">
              <Title order={3} mb="sm">
                Payment Details
              </Title>
              <Divider mb="sm" />
              <Stack spacing="xs">
                <Group position="apart">
                  <Text>Premium</Text>
                  <Text weight={700}>
                    $
                    {(
                      parseFloat(customerInfo.latestInvoice.total) / 100
                    ).toFixed(2)}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>Last Payment</Text>
                  <Text weight={700}>
                    {invoicePaidAt.toLocaleDateString("en-US")}
                  </Text>
                </Group>
              </Stack>
              <Group position="right" mt="lg">
                {!allCanceled && (
                  <Button onClick={handlePortalPayment}>
                    Manage Subscription
                  </Button>
                )}
                {allCanceled && (
                  <Button onClick={handleCheckoutPayment}>
                    Add Subscription
                  </Button>
                )}
              </Group>
            </Paper>
          </Grid.Col>

          {/* Renewal Date and Calendar */}
          <Grid.Col span={{ base: 12, xs: 4 }} md={6}>
            <Paper withBorder shadow="md" p="lg" radius="md">
              <Title order={3} mb="sm">
                Subscription Renewal Date: {nextMonth} {year}
              </Title>
              <Divider mb="sm" />
              <Center>
                <Calendar
                    month={nextInvoicePaidAt} // Show the month of nextInvoicePaidAt
                    value={nextInvoicePaidAt} // Highlight nextInvoicePaidAt
                    renderDay={(date) => {
                        const day = date.getDate();
                        const isHighlighted =
                        date.toDateString() === nextInvoicePaidAt.toDateString();
                        return (
                        <Indicator
                            size={6}
                            color="teal"
                            offset={-4}
                            position="top-end"
                            disabled={!isHighlighted}
                        >
                            <div>{day}</div>
                        </Indicator>
                        );
                    }}
                />
              </Center>
            </Paper>
          </Grid.Col>
        </Grid>
      </Container>
      <DefaultFooter isShortContent={props.isShortContent} />
    </>
  );
};

export default CustomerSubscription;