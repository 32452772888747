import React from "react";
import Aux from "../../components/Aux/Aux";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import Hero from "../../components/Hero/Hero"
import Feature from "../../components/Feature/Feature";
import HowItWorks from "./HowItWorks";

const CompanyHome = (props) => {
    return (
        <Aux>
            <Hero />
            <HowItWorks />
            <DefaultFooter isShortContent={props.isShortContent} />
        </Aux>
    )
}

export default CompanyHome;

