import {
    Box,
    Burger,
    Button,
    Divider,
    Drawer,
    Group,
    Image,
    ScrollArea,
    Avatar,
    Menu,
    Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import classes from './Header.module.css';
import Logo from '../../assets/images/logos/logoDesktop.svg';
import React, {useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Aux from '../Aux/Aux';
import { handleLogout } from '../../store/reducers/auth';
import {getCustomerDetails} from "../../store/reducers/customer";

const Header = (props) => {
    const [drawerOpened, setDrawerOpened] = useDisclosure(false);
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const role = useSelector( (state) => state.auth.role );
    const dashboadUri = `/${role?.toLowerCase()}`;
    const customerInfo = useSelector( (state) => state.customer );
    let initials =
        (customerInfo.firstname?.charAt(0).toUpperCase() || '') +
        (customerInfo.lastname?.charAt(0).toUpperCase() || '');

    const dispatch = useDispatch();

    const handleLogoutClick = () => {
        const logoutCall = async () => {
            await dispatch(handleLogout());
        };

        props?.isLoading?.(true);
        setTimeout(() => {
            logoutCall().then(() => {
                props?.isLoading?.(false);
            });
        }, 10);
    };

    useEffect(() => {
        const emptyFunction = () => {}
        const customerDetails = async () => {
            await dispatch( getCustomerDetails({ "setLoading" : emptyFunction }) );
        }

        if( isAuthenticated ) {
            customerDetails().catch( (err) => {
                console.log(err)
            })
        }
    }, [dispatch, isAuthenticated]);

    return (
        <Box pb={120}>
            <header className={classes.customHeader}>
                <Group justify="space-between" h="100%">
                    <Link to="/" className={classes.customHeaderLogoLink}>
                        <Image
                            radius="md"
                            h="30"
                            fit="contain"
                            src={Logo}
                            className={classes.customHeaderImg}
                        />
                    </Link>

                    <Group h="100%" gap={0} visibleFrom="sm">
                        <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/about">About</Link>
                        <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/pricing">Pricing</Link>
                        <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/locations">Locations</Link>
                    </Group>

                    <Group visibleFrom="sm">
                        {!isAuthenticated && (
                            <Aux>
                                <Button
                                    className={classes.customHeaderButtonInverse}
                                    variant="default"
                                    onClick={() => navigate('/login')}
                                >
                                    Log in
                                </Button>
                                <Button
                                    className={classes.customHeaderButton}
                                    onClick={() => navigate('/signup')}
                                >
                                    Sign up
                                </Button>
                            </Aux>
                        )}
                        {isAuthenticated && (
                            <Menu shadow="md" width={200}>
                                <Menu.Target>
                                    <Avatar radius="xl" size="md" style={{ cursor: 'pointer' }}>
                                        {initials}
                                    </Avatar>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Label>Account</Menu.Label>
                                    <Menu.Item onClick={() => navigate(dashboadUri)}>
                                        Dashboard
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item
                                        color="red"
                                        onClick={() => {
                                            handleLogoutClick();
                                        }}
                                    >
                                        Logout
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        )}
                    </Group>

                    <Burger
                        opened={drawerOpened}
                        onClick={setDrawerOpened.toggle}
                        hiddenFrom="sm"
                    />
                </Group>
            </header>

            <Drawer
                opened={drawerOpened}
                onClose={setDrawerOpened.close}
                size="70%"
                padding="md"
                hiddenFrom="sm"
                position="right"
                zIndex={1000000}
            >
                <ScrollArea h="calc(100vh - 80px)" mx="-md">
                    <Divider my="sm"/>
                    <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/about">About</Link>
                    <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/pricing">Pricing</Link>
                    <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/locations">Locations</Link>
                    <Divider my="sm"/>
                    <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/terms">Terms</Link>
                    <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/privacy">Privacy</Link>
                    <Divider my="sm"/>
                    <Link className={classes.customHeaderLink} onClick={() => setDrawerOpened.close()} to="/contact-us">Contact us</Link>
                    <Divider my="sm"/>

                    {isAuthenticated && (
                        <Aux>
                            <Link
                                className={classes.customHeaderLink}
                                to={dashboadUri}
                                onClick={setDrawerOpened.close}
                            >
                                Dashboard
                            </Link>
                            <Divider my="sm" />
                        </Aux>
                    )}

                    <Group justify="center" grow pb="xl" px="md">
                        {!isAuthenticated && (
                            <Aux>
                                <Button
                                    className={classes.customHeaderButtonInverse}
                                    variant="default"
                                    onClick={() => {
                                        navigate('/login');
                                        setDrawerOpened.close();
                                    }}
                                >
                                    Log in
                                </Button>
                                <Button
                                    className={classes.customHeaderButton}
                                    onClick={() => {
                                        navigate('/signup');
                                        setDrawerOpened.close();
                                    }}
                                >
                                    Sign up
                                </Button>
                            </Aux>
                        )}
                        {isAuthenticated && (
                            <Button
                                className={classes.customHeaderButton}
                                onClick={() => {
                                    handleLogoutClick();
                                    setDrawerOpened.close();
                                }}
                            >
                                Logout
                            </Button>
                        )}
                    </Group>
                </ScrollArea>
            </Drawer>
        </Box>
    );
};

export default Header;