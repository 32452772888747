import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Badge,
  Button,
  Center,
  Group,
  Text,
  Card,
  Image,
} from "@mantine/core";
import { IconBasketSearch, IconCalendarX, IconHours24, IconShoppingBag } from '@tabler/icons-react';
import classes from "./Pricing.module.css"
import DefaultFooter from "../../components/Footers/DefaultFooter";
import {useSelector} from "react-redux";

const mockdata = [
    { label: '1 hamper per week', icon: IconShoppingBag },
    { label: '24 hour cycle', icon: IconHours24 },
    { label: 'Track your hamper progress', icon: IconBasketSearch },
    { label: 'Cancel anytime', icon: IconCalendarX },
  ];

const PricingPage = () => {
    const isAuthenticated = useSelector( (state) => state.auth.isAuthenticated )
    const role = useSelector( (state) => state.auth.role );
    const redirectUri = isAuthenticated ? `/${role?.toLowerCase()}` : "/signup";
    const navigate = useNavigate();

    const features = mockdata.map((feature) => (
        <Center key={feature.label}>
          <feature.icon size={16} className={classes.pricingIcon} stroke={1.5} />
          <Text size="xs">{feature.label}</Text>
        </Center>
      ));

  return (
    <>
        <div className={classes.pricingHeroWrapper}>
        <Container size={700} className={classes.pricingHeroInner}>
            <h1 className={classes.pricingHeroTitle}>
            Simple.{' '}
            <Text component="span" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }} inherit>
                Fresh.
            </Text>{' '}
            Weekly.
            </h1>

            <Text className={classes.pricingHeroDescription} c="dimmed">
            Say goodbye to laundry day! With our single, streamlined subscription, you get one hamper of laundry cleaned, folded, and returned within 24 hours every week. No frills, just fresh clothes when you need them most.
            </Text>

            <br/>
            <br/>

            <Container size="xs" my="xl">
            <Center>
            <Card 
            withBorder 
            radius="md" 
            style={{
                maxWidth: 300, // Set the maximum width of the card
                width: "100%", // Ensure it adapts to small screens
              }}
            className={classes.pricingCard}
            >
                {/* <Card.Section className={classes.pricingImageSection}>
                    <Image src="https://i.imgur.com/ZL52Q2D.png" alt="Tesla Model S" />
                </Card.Section> */}

                <Group justify="space-between" mt="md">
                    <div>
                    <Text fw={500}>The Fresh Standard</Text>
                    <Text fz="xs" c="dimmed">
                        Weekly laundry 1 hamper per week
                    </Text>
                    </div>
                </Group>

                <Card.Section className={classes.pricingSection} mt="md">
                    <Text fz="sm" c="dimmed" className={classes.pricingLabel}>
                    Summary
                    </Text>

                    <Group gap={8} mb={-8}>
                    {features}
                    </Group>
                </Card.Section>

                <Card.Section className={classes.pricingSection}>
                    <Group gap={30}>
                    <div>
                        <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
                        $65.00
                        </Text>
                        <Text fz="sm" c="dimmed" fw={500} style={{ lineHeight: 1 }} mt={3}>
                        per month
                        </Text>
                    </div>

                    <Button radius="xl" style={{ flex: 1 }} onClick={() => navigate(redirectUri)}>
                        Get Started!
                    </Button>
                    </Group>
                </Card.Section>
            </Card>
            </Center>
        </Container>

        </Container>
        </div>
        
        <DefaultFooter />
    </>
  );
};

export default PricingPage;