import { Button, Container, Overlay, Text, Title } from '@mantine/core';
import classes from './Hero.module.css';
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const Hero = (props) => {
    const isAuthenticated = useSelector( (state) => state.auth.isAuthenticated )
    const role = useSelector( (state) => state.auth.role );
    const redirectUri = isAuthenticated ? `/${role?.toLowerCase()}` : "/signup";
    const navigate = useNavigate();

    const navigateSignup = () => {
        navigate(redirectUri);
    };

    return (
        <div className={classes.hero}>
            <Overlay
                gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                opacity={1}
                zIndex={0}
            />
            <Container className={classes.heroContainer} size="md">
                <Title className={classes.heroTitle}>
                    Remarkably Cleaner<br />Amazingly Simpler
                </Title>
                <Text className={classes.heroDescription} size="xl" mt="xl">
                    Providing fast and reliable laundry services so that you have time for what matters most.
                </Text>
                <Button
                    size="xl"
                    radius="xl"
                    className={classes.heroControl}
                    onClick={navigateSignup}
                >
                    Sign Up!
                </Button>
            </Container>
        </div>
    );
};

export default Hero;