import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  Container,
  Divider,
  Group,
  Stack,
  Table,
  Text,
  TextInput,
  PasswordInput,
  Title,
  Grid,
  Anchor,
  Progress,
  Center,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { changePassword, updateCustomerProfile } from "../../store/reducers/customer";
import PasswordStrength from "../../components/Signup/PasswordStrength";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import Spinner from "../../components/Spinner/v1/Spinner";
import { IconCheck, IconX } from "@tabler/icons-react";

const requirements = [
    { re: /[0-9]/, label: "Includes number" },
    { re: /[a-z]/, label: "Includes lowercase letter" },
    { re: /[A-Z]/, label: "Includes uppercase letter" },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
];

const getStrength = (password) => {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
        multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
};
  
const PasswordRequirement = ({ meets, label }) => (
    <Text component="div" color={meets ? "teal" : "red"} mt={5} size="sm">
        <Center inline>
        {meets ? <IconCheck size={14} stroke={1.5} /> : <IconX size={14} stroke={1.5} />}
        <Box ml={7}>{label}</Box>
        </Center>
    </Text>
);

const CustomerProfile = (props) => {
    const navigate = useNavigate();
    const role = useSelector( (state) => state.auth.role );
    const dashboadUri = `/${role?.toLowerCase()}`;
    const [loading, setLoading] = useState( false );
    const dispatch = useDispatch();
    const customerInfo = useSelector( (state) => state.customer );
    const [editProfile, setEditProfile] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const [profileSuccess, setProfileSuccess] = useState("");
    const [addressSuccess, setAddressSuccess] = useState("");
    const [passwordSuccess, setPasswordSuccess] = useState("");
    const [profileError, setProfileError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [name, setName] = useState({
        firstname: customerInfo.firstname,
        lastname: customerInfo.lastname
    });
    const [address, setAddress] = useState({
        addressLine1: customerInfo.addressLine1,
        addressLine2: customerInfo.addressLine2,
        city: customerInfo.city,
        state: "TX",
        zipCode: customerInfo.zipCode,
        country: "USA"
    });
    const [password, setPassword] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: ""
    });
    const [formError, setFormError] = useState({
        firstname: false,
        lastname: false,
        addressLine1: false,
        addressLine2: false,
        city: false,
        state: false,
        zipCode: false,
        oldPassword: false,
        password: false,
        confirmPassword: false
    });

    // Handler function for input changes
    const handleChange = (inpName, value) => {
        if( Object.keys(name).includes(inpName) ) {
            setName(prevState => ({
                ...prevState,
                [inpName]: value
            }));
        }
        else if ( Object.keys(address).includes(inpName) ) {
            setAddress(prevState => ({
                ...prevState,
                [inpName]: value
            }));
        }
        else if ( Object.keys(password).includes(inpName) ) {
            setPassword(prevState => ({
                ...prevState,
                [inpName]: value
            }));
        }
    };

    const handleUpdateProfile = () => {
        const profileUpdate= async () => {
            await dispatch( updateCustomerProfile({
                body: {
                    firstname: name.firstname,
                    lastname: name.lastname,
                    addressLine1: address.addressLine1,
                    addressLine2: address.addressLine2,
                    city: address.city,
                    state: address.state,
                    zipCode: address.zipCode,
                    country: address.country,
                },
                setLoading: setLoading,
                setError: editProfile ? setProfileError : setAddressError,
                setSuccess: editProfile ? setProfileSuccess : setAddressSuccess,
                editAddress: setEditAddress,
                editProfile: setEditProfile
            }))
        }

        setLoading(true);
        setTimeout(function() {
            profileUpdate()
        }, 10);
    }

    const handlePasswordChange = () => {
        const passwordChange = async () => {
            await dispatch( changePassword({
                body: {
                    currentPassword: password.oldPassword,
                    newPassword: password.password,
                },
                setLoading: setLoading,
                setError: setPasswordError,
                setSuccess: setPasswordSuccess,
                editPassword: setEditPassword,
            }))
        }

        setLoading(true);
        setTimeout(function() {
            passwordChange()
        }, 10);
    }

    const strength = getStrength(password.password);

    const passwordChecks = requirements.map((req, index) => (
        <PasswordRequirement key={index} label={req.label} meets={req.re.test(password.password)} />
      ));

    const passwordBars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        value={
          password.password.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

    return (
        <>
        {loading && <Spinner />}
        <Container size="lg" my="lg">
        <Anchor
            onClick={() => navigate(dashboadUri)}
            c="blue"
            size="sm"
            mb="lg"
            underline
            style={{ display: "inline-flex", alignItems: "center" }}
        >
            <IconArrowLeft size={16} style={{ marginRight: 8 }} />
            Back to dashboard
        </Anchor>
        <Grid gutter="lg">
            <Grid.Col span={{ base: 12, xs: 4 }} md={6}>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                {profileError && (
                    <Text c="red" align="center" mb="md">
                    {profileError}
                    </Text>
                )}
                <Title order={3} mb="md">
                Profile Details
                </Title>
                <Divider />
                <Stack spacing="sm" mt="md">
                <TextInput
                    label="First Name"
                    value={editProfile ? name.firstname : customerInfo.firstname}
                    onChange={(e) => handleChange("firstname", e.target.value)}
                    disabled={!editProfile}
                />
                <TextInput
                    label="Last Name"
                    value={editProfile ? name.lastname : customerInfo.lastname}
                    onChange={(e) => handleChange("lastname", e.target.value)}
                    disabled={!editProfile}
                />
                <TextInput label="Email Address" value={customerInfo.emailAddress} disabled />
                <TextInput label="Phone Number" value={customerInfo.phoneNumber} disabled />
                </Stack>
                <Group position="right" mt="md">
                {!editProfile ? (
                    <Button onClick={() => setEditProfile(true)}>Edit</Button>
                ) : (
                    <>
                    <Button
                        onClick={() => {
                        setEditProfile(false);
                        setName({
                            firstname: customerInfo.firstname,
                            lastname: customerInfo.lastname,
                        });
                        }}
                        variant="default"
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateProfile}>Save</Button>
                    </>
                )}
                </Group>
            </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 4 }} md={6}>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Title order={3} mb="md">
                Address Details
                </Title>
                <Divider />
                <Stack spacing="sm" mt="md">
                <TextInput
                    label="Street Address"
                    value={editAddress ? address.addressLine1 : customerInfo.addressLine1}
                    onChange={(e) => handleChange("addressLine1", e.target.value)}
                    disabled={!editAddress}
                />
                <TextInput
                    label="Unit Number"
                    value={editAddress ? address.addressLine2 : customerInfo.addressLine2}
                    onChange={(e) => handleChange("addressLine2", e.target.value)}
                    disabled={!editAddress}
                />
                <TextInput
                    label="City"
                    value={editAddress ? address.city : customerInfo.city}
                    onChange={(e) => handleChange("city", e.target.value)}
                    disabled={!editAddress}
                />
                <TextInput label="State" value={customerInfo.state} disabled />
                <TextInput label="Country" value={customerInfo.country} disabled />
                <TextInput
                    label="Zip Code"
                    value={editAddress ? address.zipCode : customerInfo.zipCode}
                    onChange={(e) => handleChange("zipCode", e.target.value)}
                    disabled={!editAddress}
                />
                </Stack>
                <Group position="right" mt="md">
                    {!editAddress ? (
                        <Button onClick={() => setEditAddress(true)}>Edit</Button>
                    ) : (
                        <>
                        <Button
                            onClick={() => {
                            setEditAddress(false);
                            setAddress({
                                addressLine1: customerInfo.addressLine1,
                                addressLine2: customerInfo.addressLine2,
                                city: customerInfo.city,
                                state: "TX",
                                zipCode: customerInfo.zipCode,
                                country: "USA",
                            });
                            }}
                            variant="default"
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleUpdateProfile}>Save</Button>
                        </>
                    )}
                </Group>
            </Paper>
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 4 }}>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Title order={3} mb="md">
                Update Password
                </Title>
                <Divider />
                <Stack spacing="sm" mt="md">
                <PasswordInput
                    label="Current Password"
                    value={password.oldPassword}
                    onChange={(e) => handleChange("oldPassword", e.target.value)}
                    disabled={!editPassword}
                />
                <PasswordInput
                    label="New Password"
                    value={password.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                    disabled={!editPassword}
                />
                {editPassword && 
                <>
                <Group gap={5} grow mt="xs" mb="md">
                    {passwordBars}
                </Group>
                <PasswordRequirement label="Has at least 6 characters" meets={password.password.length > 5} />
                {passwordChecks}
                </>}
                <PasswordInput
                    label="Confirm Password"
                    value={password.confirmPassword}
                    onChange={(e) => handleChange("confirmPassword", e.target.value)}
                    disabled={!editPassword}
                />
                </Stack>
                <Group position="right" mt="md">
                    {!editPassword ? (
                        <Button onClick={() => setEditPassword(true)}>Update</Button>
                    ) : (
                        <>
                        <Button
                            onClick={() => {
                            setEditPassword(false);
                            setPassword({
                                oldPassword: "",
                                password: "",
                                confirmPassword: "",
                            });
                            }}
                            variant="default"
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            handlePasswordChange();
                            setPassword({
                                oldPassword: "",
                                password: "",
                                confirmPassword: "",
                            });
                        }}>Submit</Button>
                        </>
                    )}
                </Group>
            </Paper>
            </Grid.Col>
        </Grid>
        </Container>
        <DefaultFooter isShortContent={props.isShortContent} />
        </>
    );
};

export default CustomerProfile;