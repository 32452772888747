import React from "react";
import styled from "styled-components";
import Aux from "../../Aux/Aux";
import PageTitle from "../../utility/text/PageTitle";
import {Card, CardParent} from "../../utility/containers/Card";
import PageSubheader from "../../utility/text/PageSubheader";
import Button from "../../Form/Button/v1/Button";
import Image from "../../Image/v1/Image";
import HamperImg from "../../../assets/images/images/Hamper.png"

const HamperShipped = (props) => {
    return (
        <Aux>
            <CardParent>
                <Card>
                    <ImageParent>
                        <Image url={HamperImg}/>
                    </ImageParent>
                    <PageTitle alignment={"center"}>Your Hamper has been Shipped!</PageTitle>
                    <PageSubheader alignment={"center"}>Your laundry hamper is on its way. Please verify once it arrives to ensure everything is in order. Thank you for choosing The Origami Company!</PageSubheader>
                    <Button submit={props.acknowledge}>Acknowledge</Button>
                </Card>
            </CardParent>
        </Aux>
    )
}

export default HamperShipped;

const ImageParent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    & img {
        width: 120px;
    }
`;
