import React, {useEffect, useState} from 'react';
import Aux from "../../components/Aux/Aux";
import PageContainer from "../../components/utility/containers/PageContainer";
import PageTitle from "../../components/utility/text/PageTitle";
import DefaultFooter from "../../components/Footers/DefaultFooter";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../components/Spinner/v1/Spinner";
import {addressSearch} from "../../store/reducers/employee";
import FormContainer from "../../components/Form/Container/v1/FormContainer";
import styled from "styled-components";
import PageSubheader from "../../components/utility/text/PageSubheader";
import Button from "../../components/Form/Button/v1/Button";
import Back from "../../components/Back/Back";

const EmployeeAddressList = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [unique, setUnique] = useState(true);
    const [selectedAddressText, setSelectedAddressText] = useState("");
    const [selectedAddressArray, setSelectedAddressArray] = useState([]);
    const error = useSelector( (state) => state.employee.error)
    const uniqueAddresses = useSelector( (state) => state.employee.uniqueAddresses)
    const pendingDeliveries = useSelector( (state) => state.employee.pendingDeliveries)
    const uniqueHeaders = ["Address", "City", "State", "Zip Code"]
    const pendingHeaders = ["Hamper Code", "Unit", "First Name", "Last Name", "Phone Number", "Duration"]

    useEffect(() => {
        const getPendingAddresses = async () => {
            dispatch( addressSearch({
                setLoading: setLoading
            }))
        }

        setLoading(true)
        getPendingAddresses();

    }, [dispatch]);

    const handleUniqueClick = (item) => {
        setUnique( false );
        setSelectedAddressText( item.addressLine1 + ", " + item.city + ", " + item.state + " " + item.zipCode)

        const selectedAddress = pendingDeliveries.filter( address => address.addressLine1 === item.addressLine1 && address.city === item.city && address.zipCode === item.zipCode)
        setSelectedAddressArray( selectedAddress );
    }

    return (
        <Aux>
            { loading && <Spinner /> }
            <Back to={"/employee"} text={"Back to Dashboard"} />
            <PageContainer>
                { unique &&
                    <Aux>
                        <PageTitle>Unique Addresses</PageTitle>
                        <PageSubheader>click the address to view more details</PageSubheader>
                    </Aux>
                }
                { !unique &&
                    <Aux>
                        <PageTitle>Address Specific Deliveries</PageTitle>
                        <PageSubheader>Deliveries for <b>{selectedAddressText}</b></PageSubheader>
                        <Button submit={() => { setUnique(true) }}>Back to Unique Address List</Button>
                    </Aux>
                }
                <FormContainer errorMessage={error}>
                    {unique &&
                        <Aux>
                            <TableHeadersParent isUnique={true}>
                                {uniqueHeaders?.map((name, index) => (
                                    <TableHeader key={index}>
                                        {name}
                                    </TableHeader>
                                ))}
                            </TableHeadersParent>
                            {uniqueAddresses.map((item, index) => (
                                <TableGrid key={index} isUnique={true}>
                                    <TableItem onClick={() => handleUniqueClick(item)} underline={true}>{item.addressLine1}</TableItem>
                                    <TableItem>{item.city}</TableItem>
                                    <TableItem>{item.state}</TableItem>
                                    <TableItem>{item.zipCode}</TableItem>
                                </TableGrid>
                            ))}
                        </Aux>
                    }
                    {!unique && props.screenType === 'Desktop' &&
                        <Aux>
                            <TableHeadersParent>
                                {pendingHeaders?.map((name, index) => (
                                    <TableHeader key={index}>
                                        {name}
                                    </TableHeader>
                                ))}
                            </TableHeadersParent>
                            {selectedAddressArray.map((item, index) => (
                                <TableGrid key={index}>
                                    <TableItem>{item.hamperCode}</TableItem>
                                    <TableItem>{item.addressLine2}</TableItem>
                                    <TableItem>{item.customerFirstName}</TableItem>
                                    <TableItem>{item.customerLastName}</TableItem>
                                    <TableItem><a href={"tel:" + item.phoneNumber}>{item.phoneNumber}</a></TableItem>
                                    <TableItem>{Math.floor(Number(item.cycleDurationInMin) / 60)}hr {Math.floor(parseInt(item.cycleDurationInMin) % 60)}Min</TableItem>
                                </TableGrid>
                            ))}
                        </Aux>
                    }
                    {!unique && props.screenType !== 'Desktop' &&
                        <Aux>
                            {selectedAddressArray.map((item, index) => (
                                <Aux key={index}>
                                    <TableTwoColumn>
                                        <TableItem inverse={true}>Hamper Code</TableItem>
                                        <TableItem>{item.hamperCode}</TableItem>
                                    </TableTwoColumn>
                                    <TableTwoColumn>
                                        <TableItem inverse={true}>Unit</TableItem>
                                        <TableItem>{item.addressLine2}</TableItem>
                                    </TableTwoColumn>
                                    <TableTwoColumn>
                                        <TableItem inverse={true}>First Name</TableItem>
                                        <TableItem>{item.customerFirstName}</TableItem>
                                    </TableTwoColumn>
                                    <TableTwoColumn>
                                        <TableItem inverse={true}>Last Name</TableItem>
                                        <TableItem>{item.customerLastName}</TableItem>
                                    </TableTwoColumn>
                                    <TableTwoColumn>
                                        <TableItem inverse={true}>Phone Number</TableItem>
                                        <TableItem><a href={"tel:" + item.phoneNumber}>{item.phoneNumber}</a></TableItem>
                                    </TableTwoColumn>
                                    <TableTwoColumn>
                                        <TableItem inverse={true}>Duration</TableItem>
                                        <TableItem>{Math.floor(Number(item.cycleDurationInMin) / 60)}hr {Math.floor(parseInt(item.cycleDurationInMin) % 60)}Min</TableItem>
                                    </TableTwoColumn>
                                    <MarginSpace />
                                </Aux>
                            ))}
                        </Aux>
                    }
                </FormContainer>
            </PageContainer>
            <DefaultFooter isShortContent={props.isShortContent}/>
        </Aux>
    );
};

export default EmployeeAddressList;

const MarginSpace = styled.div`
    margin: 30px;
`;

const TableHeadersParent = styled.div`
    display: grid;
    grid-template-columns: ${props => props.isUnique ? "repeat(4, 1fr)" : "repeat(6, 1fr)"};
    font-weight: bold;
    text-align: center;
    gap: 5px;
`;

const TableHeader = styled.div`
    padding: 10px 0;
    background-color: #212121;
    color: #ffffff;
`;

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: ${props => props.isUnique ? "repeat(4, 1fr)" : "repeat(6, 1fr)"};
    grid-gap: 5px;

    & div {
        border: 1px solid #212121;
    }

    &:nth-child(even) {
        & div {
            background-color: #f3f3f3;
        }
    }
`;

const TableItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;

    text-decoration: ${props => props.underline ? "underline" : "none"};
    cursor: ${props => props.underline ? "pointer" : "text"};
    color: ${props => props.underline ? "#287dda" : (props.inverse ? "#ffffff" : "#212121")};
    background-color: ${props => props.inverse ? "#212121" : "transparent"};
`;

const TableTwoColumn = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;

    & div {
        border: 1px solid #212121;
    }
`;
