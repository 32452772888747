import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux";
import Aux from "../../Aux/Aux";
import FormContainer from "../../Form/Container/v1/FormContainer";
import Input from "../../Form/Input/v1/Input";
import {verify} from "../../../store/reducers/hamper";
import QRCodeScanner from "../../QRCodeScanner/v1/QRCodeScanner";
import Image from "../../Image/v1/Image";
import QRImg from "../../../assets/images/images/QR.png";
import PageSubheader from "../../utility/text/PageSubheader";

const Verification = (props) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        hamperCode: ''
    });
    const [formError, setFormError] = useState({
        hamperCode: false
    });
    const inputRef = useRef();
    const verificationError = useSelector( (state) => state.customer.error );
    const [codeIndex, setCodeIndex] = useState(-1);
    const hamperInfo = useSelector( (state) => state.customer.hampers );

    const setError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: true
        }));
    }

    const clearError = (name) => {
        setFormError(prevState => ({
            ...prevState,
            [name]: false
        }));
    }

    const handleVerification = (data) => {
        const verification = async () => {
            const body = {
                body: {
                    hamperCode: data.toUpperCase(),
                },
                hampers: hamperInfo,
                loading: props.setLoading,
                setInput: setFormData,
                setIndex: setCodeIndex
            }
            await dispatch( verify(body) );
        }

        props.setLoading( true );
        verification();
    }

    const handleKeyPress = (event) => {
        if (event.key.length === 1 && (
            (event.key >= 'a' && event.key <= 'z') ||
            (event.key >= 'A' && event.key <= 'Z') ||
            (event.key >= '0' && event.key <= '9')) ) {
            let data = "";
            setFormData(prevState => {
                data = prevState[ "hamperCode" ] + event.key;

                if( /^[A-Za-z0-9]{5}$/.test( data ) ) {
                    handleVerification( data )
                }

                setCodeIndex( prevState => {
                    return data.length;
                });

                return {
                    ...prevState,
                    hamperCode: data.toUpperCase()
                }
            });
        }
        else if( event.key === 'Backspace' ) {
            setFormData(prevState => ({
                ...prevState,
                hamperCode: prevState[ "hamperCode" ].substring(0, prevState[ "hamperCode" ].length - 1)
            }));
            setCodeIndex( prevState => {
                if( prevState > 0 ) {
                    return (prevState - 1)
                }
                return prevState
            });
        }
    };

    const handleClick = () => {
        let idx = formData[ "hamperCode" ].length - 1;
        if( idx < 0 ) {
            idx = 0;
        }
        setCodeIndex( idx );
        inputRef.current.querySelector('input').click();
        inputRef.current.querySelector('input').focus();
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleKeyPress);
        };
    }, []);

    return (
        <FormContainer errorMessage={verificationError}>
            <Aux>
                <Relative>
                    <Hidden ref={inputRef} >
                        <Input
                            name={"hamperCode"}
                            type={"text"}
                            value={formData["hamperCode"]}
                            screenType={props.screenType}
                            hasError={formError["hamperCode"]}
                            setError={setError}
                            clearError={clearError}
                        />
                    </Hidden>
                </Relative>
                <PageSubheader alignment={"center"} ><b>Verification Code</b></PageSubheader>
                <CodeBoxParent>
                    <CodeBox active={codeIndex === 0} code={formData[ "hamperCode" ].charAt(0)} onClick={handleClick}/>
                    <CodeBox active={codeIndex === 1} code={formData[ "hamperCode" ].charAt(1)} onClick={handleClick}/>
                    <CodeBox active={codeIndex === 2} code={formData[ "hamperCode" ].charAt(2)} onClick={handleClick}/>
                    <CodeBox active={codeIndex === 3} code={formData[ "hamperCode" ].charAt(3)} onClick={handleClick}/>
                    <CodeBox active={codeIndex === 4} code={formData[ "hamperCode" ].charAt(4)} onClick={handleClick}/>
                </CodeBoxParent>
                <DividerParent>
                    <DividerLine />
                    <DividerText>OR</DividerText>
                    <DividerLine />
                </DividerParent>

                <QRCodeScanner handleScanResult={handleVerification} />
            </Aux>
        </FormContainer>
    )
}

export default Verification;

const Relative = styled.div`
    position: relative;
`;

const ImageParent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        width: 120px;
    }
`;

const DividerParent = styled.div`
    width: 100%;
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
    flex-direction: row;
`;

const DividerText = styled.div`
    width: 33%;
    color: #212121;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
`;

const DividerLine = styled.div`
    width: 33%;
    height: 1px;
    background-color: #d3d3d3;
    border-radius: 2px;
`;

const Hidden = styled.div`
    position: absolute;
    top: 0;
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;
`;

const CodeBoxParent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CodeBox = styled.div`
    display: inline-block;
    width: 70px;
    height: 70px;
    padding: 10px;
    margin: 20px;
    border: ${props => props.active ? "1px solid #212121" : "none"};
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.16);
    color: #212121;
    font-size: 16px;
    line-height: 49px;
    outline: none;
    background-color: transparent;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #212121;
        width: ${props => props.active ? "2px" : 0 };
        height: ${props => props.active ? "70%" : 0 };

        animation: blink 1s step-start infinite;

        @keyframes blink {
            0%, 100% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
        }
    }
    
    &:after {
        content: "${props => props.code }";
        position: absolute;
        font-size: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media (max-width: 768px) {
        width: 55px;
        height: 55px;
        padding: 5px;
        margin: 5px;

        &:after {
            font-size: 30px;
        }
    }

    @media (max-width: 500px) {
        width: 45px;
        height: 45px;
    }
`;
